import { config } from "../config";
import { apiGet, apiPost } from "./helper";

export async function apiAccountVerify() {
  return await apiGet(config.apiBaseUrl + "/api/Account/Verify");
}

export async function apiAccountLogin(email, password, persist) {
  return await apiPost(config.apiBaseUrl + "/api/Account/LoginCookie", {
    username: email,
    password,
    persist,
  });
}

export async function apiAccountLogout() {
  return await apiPost(config.apiBaseUrl + "/api/Account/Logout", {});
}

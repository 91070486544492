import Layout from "../components/layout";
import RequireAuth from "../components/requireAuth";

import "./App.css";
import useAuthState from "../_hooks/useAuthState";

export default function App() {

  const authState = useAuthState();

  return (
    <RequireAuth>
        <Layout>
            Your email address is {authState.userEmail}
        </Layout>
    </RequireAuth>
  );
}

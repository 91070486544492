import { configureStore, createSelector } from '@reduxjs/toolkit'

const initialState = {
  userIsAuthenticated: null,
  userEmail: "",
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'user/login':
      return { ...state, userIsAuthenticated: true, userEmail: action.userEmail }
    case 'user/logout':
      return { ...state, userIsAuthenticated: false, userEmail: "" }
    default:
      return state
  }
}

const store = configureStore({
  reducer: reducer,
  // Turn off devtools in prod, or pass options in dev
  devTools:
    process.env.NODE_ENV !== 'production'
})

const selectUserIsAuthenticated = state => state.userIsAuthenticated;
const selectUserEmail = state => state.userEmail;

export const authStateSelector = createSelector([selectUserIsAuthenticated, selectUserEmail], (isAuthenticated, email) => {
  return {
    userIsAuthenticated: isAuthenticated,
    userEmail: email,
  }
})

export default store
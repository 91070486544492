import "./layout.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useScrollingUp from "../_hooks/useScrollingUp";
import {
	Navbar,
	NavbarBrand,
	NavbarToggler,
	Collapse,
	Nav,
	NavItem,
	NavLink,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
import { IconProfile, IconLogout } from "./icons";

import { useDispatch } from "react-redux";
import { apiAccountLogout } from "../_api/account";

export default function Layout(props) {
	const scrolledUp = useScrollingUp();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);

	const onBrand = async () => {
		navigate("/", { replace: true });
	};

	const onProfile = async () => {
		navigate("/profile", { replace: true });
	};

	const onLogout = async () => {
		await apiAccountLogout();
		dispatch({ type: "user/logout" });
	};

	return (
		<div className='App'>
			<header className={`${scrolledUp ? "stickyHeader" : ""}`}>
				<Navbar
					color='dark'
					dark
					expand='sm'>
					<NavbarBrand onClick={onBrand}>
						<img
							src='/icon.png'
							width='30'
							height='30'
							className='d-inline-block align-top'
							alt='telleling'
							loading='lazy'
						/>
					</NavbarBrand>
					<NavbarToggler onClick={toggle} />
					<Collapse
						isOpen={isOpen}
						navbar>
						<Nav
							className='me-auto'
							navbar>
							<NavItem>
								<NavLink href='/components/'>Components</NavLink>
							</NavItem>
							<NavItem>
								<NavLink href='https://github.com/reactstrap/reactstrap'>
									GitHub
								</NavLink>
							</NavItem>
							<UncontrolledDropdown
								nav
								inNavbar>
								<DropdownToggle
									nav
									caret>
									Options
								</DropdownToggle>
								<DropdownMenu end>
									<DropdownItem>Option 1</DropdownItem>
									<DropdownItem>Option 2</DropdownItem>
									<DropdownItem divider />
									<DropdownItem>Reset</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>
						</Nav>
						<Nav
							className='ml-auto'
							navbar>
							<NavItem>
								<NavLink onClick={onProfile}>
									{isOpen && <span>Profil &nbsp;</span>}
									<IconProfile />
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink onClick={onLogout}>
									{isOpen && <span>Abmelden &nbsp;</span>}
									<IconLogout />
								</NavLink>
							</NavItem>
						</Nav>
					</Collapse>
				</Navbar>
			</header>
			<main>{props.children}</main>
			<footer>
				<div className='d-flex justify-content-between'>
					<div></div>
					<img
						src='/telleling.png'
						height='50'
						alt='Logo'
					/>
					<div>
						<a
							className='link'
							href='/impress'>
							Impressum
						</a>
					</div>
				</div>
			</footer>
		</div>
	);
}

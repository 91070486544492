import { Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Layout from "../components/layout";
import RequireAuth from "../components/requireAuth";

import "./App.css";
import useOnlineState from "../_hooks/useOnlineState";
import useAuthState from "../_hooks/useAuthState";
import { apiAccountLogout } from "../_api/account";

export default function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onlineState = useOnlineState();
  const authState = useAuthState();

  const onButtonClick = async () => {
    if (!authState.userIsAuthenticated) {
      navigate("/login");
    } else {
      await apiAccountLogout();
      dispatch({ type: "user/logout" });
    }
  };

  return (
    <RequireAuth>
    <Layout>
      <Button color="primary" className="text-white" onClick={onButtonClick}>
        {authState.userIsAuthenticated ? "Abmelden" : "Anmelden"}
      </Button>
      <br />
      Your email address is {authState.userEmail}
      <br />
      {onlineState ? "Online" : "Offline"}
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
        vestibulum accumsan metus et efficitur. In nec mi est. Proin eget
        pretium tortor. Fusce vulputate risus sit amet eros mollis, non
        tincidunt diam tincidunt. Praesent mattis metus quis libero hendrerit
        euismod. Sed convallis ligula nec feugiat rutrum. Proin quis metus
        turpis. Sed in posuere velit.
      </p>
      <p>
        Cras pulvinar luctus nulla in congue. Praesent id urna erat. Nulla nisl
        neque, scelerisque at fermentum eu, porttitor ultrices urna. Cras ligula
        neque, dignissim sit amet orci sit amet, ultrices faucibus enim. Nulla
        ultrices gravida libero, quis cursus orci ultricies vitae. Sed posuere
        orci id sapien efficitur, eget varius diam pellentesque. Nam aliquet
        eros sit amet libero egestas fringilla. Curabitur auctor vel justo vitae
        euismod.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
        vestibulum accumsan metus et efficitur. In nec mi est. Proin eget
        pretium tortor. Fusce vulputate risus sit amet eros mollis, non
        tincidunt diam tincidunt. Praesent mattis metus quis libero hendrerit
        euismod. Sed convallis ligula nec feugiat rutrum. Proin quis metus
        turpis. Sed in posuere velit.
      </p>
      <p>
        Cras pulvinar luctus nulla in congue. Praesent id urna erat. Nulla nisl
        neque, scelerisque at fermentum eu, porttitor ultrices urna. Cras ligula
        neque, dignissim sit amet orci sit amet, ultrices faucibus enim. Nulla
        ultrices gravida libero, quis cursus orci ultricies vitae. Sed posuere
        orci id sapien efficitur, eget varius diam pellentesque. Nam aliquet
        eros sit amet libero egestas fringilla. Curabitur auctor vel justo vitae
        euismod.
      </p>
      <p>
        Cras pulvinar luctus nulla in congue. Praesent id urna erat. Nulla nisl
        neque, scelerisque at fermentum eu, porttitor ultrices urna. Cras ligula
        neque, dignissim sit amet orci sit amet, ultrices faucibus enim. Nulla
        ultrices gravida libero, quis cursus orci ultricies vitae. Sed posuere
        orci id sapien efficitur, eget varius diam pellentesque. Nam aliquet
        eros sit amet libero egestas fringilla. Curabitur auctor vel justo vitae
        euismod.
      </p>
      <p>
        Cras pulvinar luctus nulla in congue. Praesent id urna erat. Nulla nisl
        neque, scelerisque at fermentum eu, porttitor ultrices urna. Cras ligula
        neque, dignissim sit amet orci sit amet, ultrices faucibus enim. Nulla
        ultrices gravida libero, quis cursus orci ultricies vitae. Sed posuere
        orci id sapien efficitur, eget varius diam pellentesque. Nam aliquet
        eros sit amet libero egestas fringilla. Curabitur auctor vel justo vitae
        euismod.
      </p>
      <p>
        Cras pulvinar luctus nulla in congue. Praesent id urna erat. Nulla nisl
        neque, scelerisque at fermentum eu, porttitor ultrices urna. Cras ligula
        neque, dignissim sit amet orci sit amet, ultrices faucibus enim. Nulla
        ultrices gravida libero, quis cursus orci ultricies vitae. Sed posuere
        orci id sapien efficitur, eget varius diam pellentesque. Nam aliquet
        eros sit amet libero egestas fringilla. Curabitur auctor vel justo vitae
        euismod.
      </p>
      <p>
        Cras pulvinar luctus nulla in congue. Praesent id urna erat. Nulla nisl
        neque, scelerisque at fermentum eu, porttitor ultrices urna. Cras ligula
        neque, dignissim sit amet orci sit amet, ultrices faucibus enim. Nulla
        ultrices gravida libero, quis cursus orci ultricies vitae. Sed posuere
        orci id sapien efficitur, eget varius diam pellentesque. Nam aliquet
        eros sit amet libero egestas fringilla. Curabitur auctor vel justo vitae
        euismod.
      </p>
      <p>
        Cras pulvinar luctus nulla in congue. Praesent id urna erat. Nulla nisl
        neque, scelerisque at fermentum eu, porttitor ultrices urna. Cras ligula
        neque, dignissim sit amet orci sit amet, ultrices faucibus enim. Nulla
        ultrices gravida libero, quis cursus orci ultricies vitae. Sed posuere
        orci id sapien efficitur, eget varius diam pellentesque. Nam aliquet
        eros sit amet libero egestas fringilla. Curabitur auctor vel justo vitae
        euismod.
      </p>
      <p>
        Cras pulvinar luctus nulla in congue. Praesent id urna erat. Nulla nisl
        neque, scelerisque at fermentum eu, porttitor ultrices urna. Cras ligula
        neque, dignissim sit amet orci sit amet, ultrices faucibus enim. Nulla
        ultrices gravida libero, quis cursus orci ultricies vitae. Sed posuere
        orci id sapien efficitur, eget varius diam pellentesque. Nam aliquet
        eros sit amet libero egestas fringilla. Curabitur auctor vel justo vitae
        euismod.
      </p>
      <p>
        Cras pulvinar luctus nulla in congue. Praesent id urna erat. Nulla nisl
        neque, scelerisque at fermentum eu, porttitor ultrices urna. Cras ligula
        neque, dignissim sit amet orci sit amet, ultrices faucibus enim. Nulla
        ultrices gravida libero, quis cursus orci ultricies vitae. Sed posuere
        orci id sapien efficitur, eget varius diam pellentesque. Nam aliquet
        eros sit amet libero egestas fringilla. Curabitur auctor vel justo vitae
        euismod.
      </p>
      <p>
        Cras pulvinar luctus nulla in congue. Praesent id urna erat. Nulla nisl
        neque, scelerisque at fermentum eu, porttitor ultrices urna. Cras ligula
        neque, dignissim sit amet orci sit amet, ultrices faucibus enim. Nulla
        ultrices gravida libero, quis cursus orci ultricies vitae. Sed posuere
        orci id sapien efficitur, eget varius diam pellentesque. Nam aliquet
        eros sit amet libero egestas fringilla. Curabitur auctor vel justo vitae
        euismod.
      </p>
      <p>
        Cras pulvinar luctus nulla in congue. Praesent id urna erat. Nulla nisl
        neque, scelerisque at fermentum eu, porttitor ultrices urna. Cras ligula
        neque, dignissim sit amet orci sit amet, ultrices faucibus enim. Nulla
        ultrices gravida libero, quis cursus orci ultricies vitae. Sed posuere
        orci id sapien efficitur, eget varius diam pellentesque. Nam aliquet
        eros sit amet libero egestas fringilla. Curabitur auctor vel justo vitae
        euismod.
      </p>
    </Layout>
    </RequireAuth>
  );
}

import { useEffect, useState } from "react";

const useScrollingUp = () => {
  let prevScroll = window.scrollY;
  const [scrollingUp, setScrollingUp] = useState(false);

  const handleScroll = () => {
    const currScroll = window.scrollY;
    const isScrolled = prevScroll > currScroll;
    setScrollingUp(currScroll === 0 ? false : isScrolled);
    prevScroll = currScroll;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll, {
        passive: true,
      });
    };
  });
  return scrollingUp;
};

export default useScrollingUp;

export async function apiGet(url) {
	return await fetch(url, {
		credentials: "include",
		method: "GET",
	})
		.then(apiProcessResult)
		.catch(apiProcessException);
}

export async function apiPost(url, data) {
	return await fetch(url, {
		credentials: "include",
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(data),
	})
		.then(apiProcessResult)
		.catch(apiProcessException);
}

async function apiProcessResult(r) {
	if (r.status === 200) {
		return await r.json();
	} else {
		return {
			succeeded: false,
			message: "Ungültige Anmeldeinformationen.",
			status: r.status,
		};
	}
}

function apiProcessException(e) {
	return {
		succeeded: false,
		message: "Es ist ein Fehler aufgetreten.",
		exception: e,
	};
}

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Input, Label, Form, FormGroup } from "reactstrap";
import { authStateSelector } from "../store";

import "./login.css";
import { apiAccountLogin } from "../_api/account";

const Login = (props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const authState = useSelector((state) => authStateSelector(state));

	const location = useLocation();
	const { from } = location.state || {};

	const [stateEmail, setStateEmail] = useState("");
	const [statePwd, setStatePwd] = useState("");
	const [statePersist, setStatePersist] = useState(true);
	const [stateError, setStateError] = useState("");

	useEffect(() => {
		if (authState.userIsAuthenticated) {
			navigate(from?.pathname || "/", { replace: true });
		}
	});

	const onButtonClick = async () => {
		setStateError("");

		if (stateEmail === "") {
			setStateError("Bitte geben Sie eine E-Mail-Adresse ein.");
			return;
		}

		if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(stateEmail)) {
			setStateError("Bitte geben Sie eine gülitge E-Mail-Adresse ein.");
			return;
		}

		if (statePwd === "") {
			setStateError("Bitte geben Sie ein Passwort ein.");
			return;
		}

		const result = await apiAccountLogin(stateEmail, statePwd, statePersist);

		if (result.succeeded) {
			dispatch({ type: "user/login", userEmail: stateEmail });
		} else {
			setStateError("Anmeldung fehlgeschlagen.");
		}
	};

	const onKeyDown = (event) => {
		if (event.key === "Enter") {
			onButtonClick();
		}
	};

	return (
		<div className='container-fluid h-100'>
			<div className='row justify-content-center align-items-center h-100'>
				<div className='col col-sm-7 col-md-6 col-lg-5 col-xl-4'>
					<div className='card shadow'>
						<div className='card-body'>
							<div className='text-center text-white bg-dark shadow login-header'>
								<h3>
									<img
										src='/icon.png'
										width='30'
										height='30'
										alt='logo'
									/>{" "}
									Fahrdienst
								</h3>
							</div>
							<Form>
								<div className='my-3'>
									<Input
										value={stateEmail}
										placeholder='E-Mail...'
										onChange={(ev) => setStateEmail(ev.target.value)}
										onKeyDown={onKeyDown}
									/>
								</div>
								<div className='my-3'>
									<Input
										value={statePwd}
										placeholder='Passwort...'
										type='password'
										onChange={(ev) => setStatePwd(ev.target.value)}
										onKeyDown={onKeyDown}
									/>
								</div>
								<div>
									<FormGroup switch>
										<Input
											type='switch'
											checked={statePersist}
											onChange={() => {
												setStatePersist(!statePersist);
											}}
										/>
										<Label check>Angemeldet bleiben</Label>
									</FormGroup>
								</div>
								<Label className='errorLabel'>{stateError}</Label>
								<div className='mt-3'>
									<Button
										color='primary text-white'
										onClick={onButtonClick}>
										Anmelden
									</Button>
									<Button
										color='secondary'
										outline
										className='float-end'>
										Passwort vergessen...
									</Button>
								</div>
							</Form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;

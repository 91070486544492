import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { authStateSelector } from "../store";
import { apiAccountVerify } from "../_api/account";

export default function useAuthState() {
	const dispatch = useDispatch();
	const authState = useSelector((state) => authStateSelector(state));

	useEffect(() => {
		const getAuthState = async () => {
			const result = await apiAccountVerify();

			if (result.succeeded) {
				dispatch({ type: "user/login", userEmail: result.email });
			} else {
				dispatch({ type: "user/logout" });
			}
		};

		if (!authState.userIsAuthenticated) {
			getAuthState();
		}
	});

	return authState;
}

import useAuthState from "../_hooks/useAuthState";
import { Navigate, useLocation } from "react-router-dom";

export default function RequireAuth(props) {
	const authState = useAuthState();
	const location = useLocation();

	if (authState.userIsAuthenticated === null) {
		return <></>;
	}

	if (authState.userIsAuthenticated === false) {
		return (
			<Navigate
				to='/login'
				replace
				state={{ from: location }}
			/>
		);
	}

	return <>{props.children}</>;
}
